//Mobile------------------------------------------------------------------------------------------------------
.image-loader {
	@include absoluteCover();
	pointer-events: none;
	display: block;
	background-color: var(--image-loader-background);
	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: var(--image-loader-color);
		&.double {
			&:after {
				border-color: var(--image-loader-spinner-color) transparent
					var(--image-loader-spinner-color) transparent;
			}
		}
		&.ring {
			div {
				border: var(--image-loader-spinner-color) solid
					var(--image-loader-spinner-color);
				border-color: var(--image-loader-spinner-color) transparent
					transparent transparent;
			}
		}
		&.ripple {
			div {
				border-color: var(--image-loader-spinner-color);
			}
		}
		&.ellipsis {
			div {
				background: var(--image-loader-spinner-color);
			}
		}
	}
	&.fade-out {
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.image-loader {
		transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.4s;
		&.fade-out {
			transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.4s;
		}
	}
}
