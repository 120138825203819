//Mobile------------------------------------------------------------------------------------------------------
.button{
	padding: 7px 12px;
	font-size: 14px;
	text-decoration: none;
	font-family: var(--font-secondary);
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	color: var(--color-primary);
	font-weight: 500;
	position: relative;
	&:before {
		content: '';
		width: 2px;
		height: 100%;
		position: absolute;
		left: 0;
		transition: all 0.3s;
		background: var(--color-primary);
	}
	span {
		display: block;
		transition: all 0.3s;
		position: relative;
	}
	&.negative {
		&::before {
			background: #FFF;
		}
		span {
			color: #FFF;
		}
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.button{
		&:hover {
			span {
				color: #FFF;
			}
			&:before {
				width: 100%;
			}
		}
		&.negative {
			&:hover {
				span {
					color: var(--color-primary);
				}
			}
		}
	}
}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
