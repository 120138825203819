// absolute positioning 100% cover
@mixin absoluteCover() {
	position: absolute !important;
	inset: 0;
	width: 100%;
	height: 100%;
}

// img absolute positioning 100% cover
@mixin imgAbsoluteCover {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

// img overlay
@mixin imgOverlay($bgcolor, $opacity) {
	position: relative;
	&:after {
		content: "";
		display: block;
		@include absoluteCover();
		background-color: $bgcolor;
		opacity: $opacity;
	}
}

// limits number of text lines
@mixin trunkLine($num) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $num;
	-webkit-box-orient: vertical;
}

// fix safari border radius
@mixin safariRadiusFix() {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

// pixel to rem (base 16px)
@mixin frem($size) {
	font-size: #{calc($size / 16)}rem;
}
