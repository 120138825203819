//Mobile------------------------------------------------------------------------------------------------------
.image {
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
	&.overlay {
	}
	&.gradient {
	}
	.ratio {
		position: relative;
		padding-top: 100%;
		img,
		picture {
			@include imgAbsoluteCover;
		}
		&.square {
			padding-top: var(--aspect-ratio-1x1);
		}
		&.landscape {
			padding-top: var(--aspect-ratio-4x3);
		}
		&.portrait {
			padding-top: var(--aspect-ratio-3x4);
		}
		&.cinema {
			padding-top: var(--aspect-ratio-16x9);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
