//Mobile------------------------------------------------------------------------------------------------------
.footer{
	h3 {
		color: #cb99a9;
		font-size: 16px;
		text-transform: uppercase;
	}
	.custom-col-3 {
		margin-bottom: 25px;
	}
	.lined {
		&:after {
			background-color: #e5ccd4;
			margin: 10px 0;
		}
	}
	p {
		color: #cb99a9;
		&.address, &.iva {
			line-height: 35px
		}
	}
	a.mail {
		color: #fff;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	.inner-content {
		background-color: var(--color-primary);
		color: #cb99a9;
	}
	.footer-top {
		padding: 10px 20px;
		box-sizing: border-box;
	}
	.icon {
		fill: var(--color-primary);
		transition: all 0.3s;
	}
	a:hover {
		.icon {
			fill: var(--color-primary-hover);
			transform-origin: center;
			transform: scale(1.1);
		}
	}
	h2 {
		font-weight: 500;
		font-size: 26px;
		margin-bottom: 10px;
	}
	.footer-inner {
		background-color: var(--color-primary);
		padding: 20px;
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-top: 14px;
		li {
			margin-bottom: 5px;
		}
		a {
			color: #cb99a9;
			text-decoration: none;
			font-size: 14px;
			transition: all 0.3s;
			&:hover {
				color: #FFF;
			}
		}
	}
	.orari {
		p {
			margin-bottom: 20px;
			span {
				color: #FFF;
				font-weight: 500;
			}
		}
	}
	.footer-bottom {
		.inner-content {
			background: #FFF;
			padding-top: 20px;
			p {
				color: #656460;
				a {
					color: #32302b;
					font-weight: 500;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.footer-right {
		background-color: #FFF;
		border-radius: 15px;
		box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.27);
		padding: 10px;
	}
	.policy {
		border-top: 1px solid #e5ccd4;
		padding-top: 10px;
		margin-top: 50px;
		p {
			font-size: 12px;
			span {
				color: #e5ccd4;
			}
		}
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.footer {
		margin-left: 340px;
		.icon {
			margin-right: 15px;
		}

		.footer-top {
			width: 66.6666%;
			padding-left: 50px;
		}
		h2 {
			margin-bottom: 0px;
		}
		.footer-inner {
			padding: 30px 50px;
			position: relative;
		}
		.footer-left {
			width: 66.6666%;
		}
		.custom-col-3 {
			margin-bottom: 0;
		}
		.footer-right {
			background-color: #FFF;
			border-radius: 15px;
			box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.27);
			position: absolute;
			right: 50px;
			top: -70px;
			width: 30%;
			padding: 20px;
		}
	}

}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
