//Mobile------------------------------------------------------------------------------------------------------
header{
	z-index: 20;
	a {
		color: var(--header-color);
		text-decoration: none;
		font-size: 14px;
		.icon {
			fill: var(--header-color);
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
	}
	.contact-zone {
		font-size: 14px;
		.icon {
			width: 23px;
			height: 23px;
			margin-right: 5px;
			transition: all 0.3s;
		}
	}
	nav {
		margin-left: -7px;
		a {
			font-family: var(--font-secondary);
			text-transform: uppercase;
			display: inline-block;
			padding: 5px 7px;
			transition: all 0.3s;
			&.active {
				pointer-events: none;
				span {
					font-weight: 600;
					color: #bebdbb;
				}
				&:after {
					width: 1px;
				}
			}
		}
	}
	.social-zone {
		border-top: 2px solid #534f4a;
		padding-top: 31px;
		a {
			margin-right: 10px;
			.icon {
				transition: all 0.3s;
			}
		}
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {
	header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		.nav-wrapper {
			padding: 24px;
			background: var(--header-bg);
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			transition: all 0.3s;
			transform: translateX(-100%);
			pointer-events: none;
			&.opened {
				transform: translateX(0%);
				pointer-events: all;
			}
		}
		.nav-wrapper {
			a {
				padding: 10px 7px;
			}
		}
		.close-menu {
			width: 45px;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--color-primary);
			position: absolute;
			right: 0;
			top: 0;
			padding: 0;
			border: none;
			.icon {
				fill: #FFF;
			}
		}
		.contact-zone {
			margin-bottom: 25px;
			margin-top: 50px;
		}
		.logo-wrapper {
			padding: 12px;
			position: relative;
			background: var(--header-bg);
			justify-content: center;
		}
		.toggler {
			background-color: transparent;
			border: none;
			padding: 0;
			position: absolute;
			right: 24px;
			top: 65px;
			span {
				display: block;
				height: 3px;
				width: 25px;
				background-color: #bebdbb;
				margin: 6px 0;
			}
		}
		a.logo {
			width: 250px;
		}
	}
}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	header{
		background-color: var(--header-bg);
		padding: 25px 30px;
		width: 340px;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		box-sizing: border-box;
		.contact-zone {
			margin-bottom: 28px;
			a {
				transition: all 0.3s;
			}
			a:hover {
				color: #FFF;
				.icon {
					fill: #FFF;
				}
			}
		}
		nav {
			li {
				margin-bottom: 25px;
			}
			a {
				position: relative;
				span {
					position: relative;
					transition: all 0.3s;
					z-index: 1;
				}
				&:after {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					bottom: 0;
					left: 0;
					width: 0%;
					background-color: #bebdbb;
					transition: all 0.3s;
				}
				&:hover {
					span {
						color: #28231d;
					}
					&:after {
						width: 100%;
					}
				}
			}
		}
		a.logo {
			margin-bottom: 23px;
		}
		.social-zone {
			a:hover {
				.icon {
					fill: #FFF;
				}
			}
		}
	}
}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
