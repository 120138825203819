//Mobile------------------------------------------------------------------------------------------------------
body {
	background-color: var(--body-bg);
	color: var(--body-color);
	font-family: var(--font-primary);
}

a {
	color: var(--link-color);
}

button {
	margin: 0;
}
.inner-content {
	padding:20px;
}
.custom-container {
	overflow: hidden;
}
.custom-row {
	margin-left: -20px;
	margin-right: -20px;
	display: flex;
	flex-wrap: wrap;
}
.custom-col-3 {
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
}
.custom-col-12 {
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
}
.custom-col-6 {
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
}
main {
	margin-top: 139px;
}


//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	main {
		margin-left: 340px;
		margin-top: 0;
	}
	.inner-content {
		padding:50px;
	}
	.custom-row {
		margin-left: -25px;
		margin-right: -25px;
		display: flex;
	}
	.custom-col-3 {
		padding-left: 25px;
		padding-right: 25px;
		width: 33.3333%;
	}
	.custom-col-6 {
		padding-left: 25px;
		padding-right: 25px;
		width: 66.6666%;
	}
	.custom-col-12 {
		padding-left: 25px;
		padding-right: 25px;
		width: 100%;
	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
