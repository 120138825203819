h1,h2,h3 {
	font-family: var(--font-secondary);
	font-weight: 400;
}
h2 {
	font-size: 30px;
	font-weight: 300;
	color: var(--color-primary);
}
p {
	font-size: 14px;
	color: var(--text-color);
}
.lined {
	&:after {
		content: '';
		display: block;
		height: 2px;
		background-color: #7d7b79;
		width: 150px;
		margin: 25px 0;
	}
}


