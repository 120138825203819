//Mobile------------------------------------------------------------------------------------------------------
.gallery{
	padding: 30px 0;
	a.img-wrapper {
		margin-bottom: 30px;
		padding-top: 133.33%;
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.lg-sub-html {
	font-family: var(--font-secondary);
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
