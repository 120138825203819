//Mobile------------------------------------------------------------------------------------------------------
.hero-card{
	position: relative;
	.text-container {
		color: #FFF;
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
	}
	p {
		font-size: 16px;
		color: #FFF;
	}
	h2 {
		font-size: 30px;
		color: #FFF;
	}
	.image {
		padding-top: 120%;
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.lined {
		&:after {
			background-color: #FFF;
		}
	}
	.button {
		margin-top: 35px;
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.hero-card{
		h2 {
			font-size: 40px;
		}
		.image {
			padding-top: 59.01%;
			min-height: 600px;
		}
		.inner-content {
			padding:50px;
		}
		.text-container {
			padding: 0 0 80px 50px;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 350px;
			box-sizing: border-box;
		}
	}
}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
