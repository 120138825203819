//Mobile------------------------------------------------------------------------------------------------------
.swiper-carousel {
	position: relative;
	overflow: hidden;
	.swiper-nav {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.brand {
		position: absolute;
		right: 0px;
		top: 0px;
		z-index: 1;
		width: 100px;
	}
	.swiper-button-prev, .swiper-button-next {
		position: relative;
		top: auto;
		width: 40px;
		height: 40px;
		background-color: var(--color-primary);
		right: auto;
		left: auto;
		margin-top: auto;
		transition: all 0.3s;
		&:hover {
			background-color: var(--color-primary-hover);
		}
		.icon {
			fill: #FFF;
			width: 14px;
			height: 14px;
		}
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.swiper-carousel {
		.brand {
			right: 15px;
			top: 15px;
			width: auto;
		}
	}
}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
