:root {
	//-colors
	--color-primary: #7c0027;
	--color-primary-hover: #5f001e;
	--color-secondary: #b35101;
	--color-secondary-hover: #5f2b00;
	--text-color: #000;
	--border-color: #aaa;
	--border-color-light: #eee;
	--border-color-lighter: #fafafa;
	--text-color: #666461;
	//-general
	--body-bg: #fff;
	--body-color: var(--text-color);
	--link-color: var(--color-primary);
	--border-radius: 8px;
	//-fonts
	--font-primary: 'IBM Plex Sans', sans-serif;
	--font-secondary: 'IBM Plex Sans Condensed', sans-serif;
	//-typography
	--header-font: var(--font-primary);
	--header-weight: 500;
	--header-color: var(--color-primary);
	--header-line-height: 1.1;
	--header-margin-bottom: 0.15em;
	--h1-font-size: 3rem;
	--h2-font-size: 2rem;
	--h3-font-size: 1.5rem;
	--h4-font-size: 1.25rem;
	--h5-font-size: 1.125rem;
	--h6-font-size: 1rem;
	--p-font-size: 1rem;
	--p-margin-bottom: 1em;
	--p-line-height: 1.6;
	--p-color: var(--text-color);
	--hr-size: 1px;
	--hr-color: var(--border-color);
	--hr-margin: 1rem;
	//-transition
	--transition-default: all 0.3s ease-in-out;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;


	// focus outline
	--outline-color: orange;
	--outline-width: 2px;
	//spinner
	--spinner-color: var(--color-primary);
	--spinner-dimension: 40px;
	--spinner-border: 4px;
	//image loader
	--image-loader-background: #aaa;
	--image-loader-color: #000;
	--image-loader-spinner-color: rgba(255, 255, 255, 0.8);
	//aspect ratio
	--aspect-ratio-1x1: 100%;
	--aspect-ratio-4x3: 75%;
	--aspect-ratio-3x4: 133.3333%;
	--aspect-ratio-16x9: 56.25%;
	//back to top
	--backtotop-zindex: 10;
	--backtotop-size-sm: 40px;
	--backtotop-size-lg: 50px;
	--backtotop-position-right-sm: 25px;
	--backtotop-position-btm-sm: 40px;
	--backtotop-position-right-lg: 25px;
	--backtotop-position-btm-lg: 40px;
	--backtotop-radius: 50%;
	--backtotop-bg-color: var(--color-primary);
	--backtotop-border: none;
	--backtotop-icon-color: #fff;


	//-FORMS-----------------------
	//-header
	--header-bg: #28231d;
	--header-color: #7e7b77;
}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
