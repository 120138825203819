//Mobile------------------------------------------------------------------------------------------------------
.title-text{
	p {
		span {
			font-weight: 500;
			color: var(--color-primary);
		}
	}
	&.negative {
		background-color: var(--color-primary);
		p, h2 {
			color: #FFF;
		}
		.lined {
			&:after {
				background-color: #FFF;
			}
		}
	}
	.button {
		margin-top: 25px;
	}
	.image-wrapper {
		margin-top: 25px;
		.image {
			padding-top: 122.50%;
			position: relative;
			overflow: hidden;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.map-container {
		position: relative;
		padding-top: 56.25%;
		margin-bottom: 20px;
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.title-text {
		.button {
			margin-top: 50px;
		}
		.image-wrapper {
			margin-top: 0;
			margin-left: 50px;
			.image {
				width: 300px;
			}
		}
	}
}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
