//Mobile------------------------------------------------------------------------------------------------------
.cookie{
	h3 {
		color: var(--color-primary);
		font-size: 18px;
		font-family: var(--font-primary);
		margin-bottom: 20px;
	}
	h4 {
		color: var(--color-primary);
		font-size: 16px;
		font-family: var(--font-primary);
	}
	p{
		margin-bottom: 20px;
		a {
			text-decoration: none;
			font-weight: 600;
		}
	}
	ul {
		font-size: 14px;
		margin: 0;
		padding: 0;
		padding-left: 1rem;
	}
	p + ul {
		margin-top: -20px;
	}
	ul {
		ul {
			list-style-type: '- ';
			padding-left: 10px;
		}
	}
	ul + h4 {
		margin-top: 20px;
	}
}
//Mobile only-------------------------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, md)-1}) {

}


//Mobile / Talblet vertical only------------------------------------------------------------------------------
@media (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet vertical---------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}


//Tablet vertical only----------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)-1}) {

}


//Tablet horizontal / small desktop---------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}


//desktop------------------------------------------------------------------------------------------------------
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}


//desktop xl---------------------------------------------------------------------------------------------------
@media (min-width: 1440px) {

}


//desktop xxl--------------------------------------------------------------------------------------------------
@media (min-width: 1600px) {

}
